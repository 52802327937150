import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"

export default () => (
  <>
    <Layout>
      <Hero />

        {/* Style Guide Section
        ================================================== */}
        <section id="styles"
          style= {{
            padding: '90px 40px 72px',
            background: '#fff'
          }}
        >
          <div className="row section-head">
            <h1>GENERAL TERMS OF USE</h1>
          </div>

           <div className="row" >


                 <h5>Article 1: OBJECT</h5>

                 
                  <div>The present General Terms of Use provide a legal framework for the use of the <b>Juliette Chastel - Traductrice</b> website and its services. Access to the site implies acceptance of these Terms of Use.</div>
                

                 <h5>Article 2: LEGAL NOTICE</h5>
                 <ul >
                <li>Juliette Chastel - Traductrice</li>
                <li>Head office: 115 rue de Reuilly, 75012 PARIS</li>
                <li>E-mail: jchastel.traduction@gmail.com Phone: +33173710384</li>
                <li>French microenterprise regime</li>
                <li>SIRET: 904 556 156 00019</li>
                <li>Exempt from registration in the French Trade and Companies Register (Registre du commerce et des sociétés, RCS)</li>
                <li>Legal representative: Juliette Chastel</li>
                <li>Hosting:Netlify, Inc. Head office: 2325 3RD STREET,SUITE 215, SAN FRANCISCO, CA 94107 (USA).</li>
                <li>Website creation: Pierrick Houede.</li>
                </ul>
                <h5>Article 3: ACCESS TO SERVICES</h5>
                
                  <div>The <b>Juliette Chastel – Traductrice</b> website provides access to the following services:</div>
                
                <ul >
                <li>Translation and correction services</li>
                <li>Transcription and subtitling services</li>
                <li>Terminology and editorial services</li>
                </ul>
                

                <h5>Article 4: COLLECTION OF DATA</h5>

                 
                  <div>The electronic address (e-mail) provided by the User may be used by the <b>Juliette Chastel – Traductrice</b> website to communicate various information.
                     </div>
                     <div><b>Juliette Chastel – Traductrice</b> guarantees the respect of the User's private life, in accordance with the French law n°78-17 of January 6, 1978 about data processing, files and freedoms.
                     </div>
                     <div>In accordance with articles 39 and 40 of the French law or January 6, 1978, the User has the right to access, rectify, delete and oppose his personal data.
                     </div>
                     <div>The User exercises this right by e-mail to jchastel.traduction@gmail.com 
                     </div>
                

                <h5>Article 5: INTELLECTUAL PROPERTY</h5>

                 
                  <div>The logo and the contents of the <b>Juliette Chastel - Traductrice</b> website are protected by the French Intellectual Property Code and by copyright.
                     </div>
                <div>The reproduction and copying of the contents by the User require prior authorisation from the site. In this case, any use for commercial or advertising purposes is prohibited.
                    </div>
                
            </div>

           

        </section> {/* Style Guide Section End*/}

    </Layout>
  </>
)